/* eslint-disable radix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import {
  TextField, Button, Typography, Box, IconButton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import { CommHandler } from '../../../../utils';
import { config } from '../../../../config';
import ChatMessages from './ChatMessages';
import socket from '../../../../service/socketService';

const Chat = ({ artifactFormDetails, onClose, showChat }) => {
  const loggedInUserFirstName = localStorage.getItem('firstName');
  const loggedInUserLastName = localStorage.getItem('lastName');
  const { artifactFormId } = useParams();
  const [message, setMessage] = useState('');
  const [canChat, setCanChat] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingMessageText, setEditingMessageText] = useState('');

  const chatContainerRef = useRef(null);

  useEffect(() => {
    fetchChatMessages();
  }, []);

  useEffect(() => {
    socket.on('receiveMessage', (data) => {
      const {
        message, sender, artifactId, sendAt, id,
      } = data;
      const currentUserName = `${loggedInUserFirstName} ${loggedInUserLastName}`;
      if (sender !== currentUserName) {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          {
            message,
            sender,
            sendAt,
            artifactId,
            id,
          },
        ]);
      }
    });

    socket.on('editMessage', (editedMessage) => {
      setChatMessages((prevMessages) => prevMessages.map((msg) => (msg.id === editedMessage.id ? { ...msg, ...editedMessage, isEdited: true } : msg)));
    });

    socket.on('deleteMessage', (data) => {
      const { chatId } = data;
      setChatMessages((prevMessages) => prevMessages.map((msg) => {
        if (msg.id === chatId) {
          return { ...msg, isDeleted: true, message: ' this' };
        }
        return msg;
      }));
    });

    return () => {
      socket.off('receiveMessage');
      socket.off('editMessage');
      socket.off('deleteMessage');
    };
  }, [editingMessageId, loggedInUserFirstName, loggedInUserLastName, setChatMessages, setEditingMessageText, chatMessages, socket]);

  const isAtBottomRef = useRef(true);
  useEffect(() => {
    if (isAtBottomRef.current) {
      scrollToBottom();
    }
  }, [chatMessages]);

  const fetchChatMessages = async () => {
    try {
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/getArtifact`,
        method: 'POST',
        data: { id: artifactFormId },
      });

      if (response?.data) {
        const artifact = response.data;
        const approverList = artifact.approverList || [];
        const loggedInUserId = localStorage.getItem('user_id');
        const canChat = approverList.some((approver) => parseInt(approver.id) === parseInt(loggedInUserId)) || parseInt(artifact.user_id) === parseInt(loggedInUserId);
        const { chats } = artifact;
        const chatMessages = chats.flatMap((chat) => chat.chats.map((message) => ({
          id: message.id,
          message: message.message,
          sender: message.sendBy || `${loggedInUserFirstName} ${loggedInUserLastName}`,
          sendAt: message.sendAt,
          isEdited: message.isEdited,
          createdAt: message.createdAt,
          isDeleted: message.isDeleted,
        })));
        // Convert sendAt to timestamps for proper comparison
        chatMessages.forEach((msg) => {
          // eslint-disable-next-line no-param-reassign
          msg.sendAtTimestamp = new Date(msg.sendAt).getTime();
        });
        chatMessages.sort((a, b) => a.sendAtTimestamp - b.sendAtTimestamp);
        setChatMessages(chatMessages);
        setCanChat(canChat);
        setEditingMessageId(null);
        scrollToBottom();
      }
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
  };

  const sendMessage = async () => {
    if (message.trim() !== '') {
      const senderName = `${loggedInUserFirstName} ${loggedInUserLastName}`;
      const newMessage = {
        message,
        sender: senderName,
        userId: artifactFormDetails?.user_id,
        sendAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
      };
      try {
        const response = await postChatMessage(newMessage);
        if (response && response.data && response.data.chats) {
          const updatedChats = response.data.chats.map((chat) => ({
            ...chat,
            sender: chat.sendBy,
          }));
          setChatMessages((prevMessages) => [...prevMessages, ...updatedChats]);
          scrollToBottom();
        }
        socket.emit(
          'sendMessage',
          {
            message: newMessage.message,
            sender: senderName,
            artifactId: artifactFormId,
          },
          { broadcast: true, includeSelf: false },
        );
        setMessage('');
      } catch (error) {
        console.error('Error sending chat message:', error);
      }
      setMessage('');
    }
  };

  const postChatMessage = async (newMessage) => {
    try {
      const artifactId = artifactFormId;
      const chatData = {
        sendAt: new Date().toISOString(),
        sendBy: newMessage.sender,
        message: newMessage.message,
      };
      const requestBody = {
        artifact_id: artifactId,
        chats: [chatData],
      };
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/chats/createChats`,
        method: 'POST',
        data: requestBody,
      });
      return response;
    } catch (error) {
      console.error('Error posting chat message:', error);
    }
  };

  const editChatMessage = async (updatedMessage) => {
    try {
      const requestBody = {
        artifact_id: artifactFormId,
        chats: [
          {
            id: updatedMessage.id,
            message: updatedMessage.message,
          },
        ],
      };
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/chats/editChat`,
        method: 'PUT',
        data: requestBody,
      });
      return response;
    } catch (error) {
      console.error('Error editing chat message:', error);
    }
  };

  const deleteChatMessage = async (chatId) => {
    try {
      const requestBody = {
        artifact_id: artifactFormId,
        chat_id: chatId,
      };
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/chats/deleteChat`,
        method: 'DELETE',
        data: requestBody,
      });
      if (response && response.data) {
        const updatedMessages = chatMessages.map((msg) => {
          if (msg.id === chatId) {
            return { ...msg, message: 'This message is deleted', isDeleted: true };
          }
          return msg;
        });
        setChatMessages(updatedMessages);
      }
      socket.emit('deleteMessage', { chatId, artifactId: artifactFormId });
    } catch (error) {
      console.error('Error deleting chat message:', error);
    }
  };

  const handleEditMessage = (msg) => {
    setEditingMessageId(msg.id);
    setEditingMessageText(msg.message);
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setEditingMessageText('');
  };

  const handleConfirmEdit = async () => {
    if (editingMessageText.trim() !== '') {
      const updatedMessage = {
        ...chatMessages.find((msg) => msg.id === editingMessageId),
        message: editingMessageText,
        isEdited: true,
      };
      try {
        const response = await editChatMessage(updatedMessage);
        if (response && response.data) {
          const updatedChatMessages = [...chatMessages];
          const index = updatedChatMessages.findIndex((msg) => msg.id === editingMessageId);
          updatedChatMessages[index] = updatedMessage;
          setChatMessages(updatedChatMessages);
          socket.emit('editMessage', {
            ...updatedMessage,
            artifactId: artifactFormId.toString(),
          });
          setEditingMessageId(null);
          setEditingMessageText('');
        }
      } catch (error) {
        console.error('Error updating chat message:', error);
      }
    }
  };

  const isSendDisabled = message.trim() === '';

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = Date.now();
      const updatedMessages = chatMessages.map((msg) => {
        const elapsedTime = currentTime - new Date(msg.sendAt).getTime();
        const disableActions = elapsedTime >= 600000;
        return { ...msg, disableActions };
      });
      setChatMessages(updatedMessages);
    }, 1000);
    return () => clearInterval(timer);
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      isAtBottomRef.current = scrollTop + clientHeight >= scrollHeight - 50;
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (editingMessageId) {
        handleConfirmEdit();
      } else {
        sendMessage();
      }
    }
  };

  return (
    <>
      {showChat && (
        <Box
          position="fixed"
          bottom={10}
          right={30}
          bgcolor="#fff"
          p={1}
          boxShadow={3}
          borderRadius={2}
          width={500}
          height={550}
          overflow="auto"
          display="flex"
          flexDirection="column"
          sx={{
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            sx={{
              backgroundColor: '#2559C3',
              color: 'white',
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant="h6" gutterBottom>
              <IconButton size="small" color="inherit">
                <CommentIcon />
              </IconButton>
              Comments
            </Typography>
            <IconButton size="small" onClick={onClose} color="inherit">
              <CloseIcon />
            </IconButton>
          </Box>
          {/* <Divider /> */}
          <Box mb={2} maxHeight="600px" overflow="auto" flexGrow={1} ref={chatContainerRef} onScroll={handleScroll}>
            <ChatMessages
              chatMessages={chatMessages}
              editingMessageId={editingMessageId}
              // loggedInUserFirstName={loggedInUserFirstName}
              // loggedInUserLastName={loggedInUserLastName}
              handleEditMessage={handleEditMessage}
              deleteChatMessage={deleteChatMessage}
              handleConfirmEdit={handleConfirmEdit}
              handleCancelEdit={handleCancelEdit}
              handleKeyPress={handleKeyPress}
              editingMessageText={editingMessageText}
              setEditingMessageText={setEditingMessageText}
            />
          </Box>
          {canChat && (
            <Box mt="auto">
              <Box display="flex" alignItems="center">
                <TextField
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  placeholder="Type your message here..."
                  autoFocus
                  onKeyPress={handleKeyPress}
                  sx={{
                    borderRadius: '10px',
                  }}
                />
                <Box ml={2}>
                  <Button
                    onClick={sendMessage}
                    variant="contained"
                    color="primary"
                    disabled={isSendDisabled}
                    startIcon={<SendIcon />}
                    sx={{
                      borderRadius: '10px',
                    }}
                  >
                    Send
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
      ;
    </>
  );
};

export default Chat;
