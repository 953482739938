import React, { Suspense } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ErrorBoundary } from './components';
import { theme } from './layouts';
import AppRouter from './routes/AppRouter';
import Snackbar from './components/Snackbar/Snackbar';

const App = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Suspense>
          <AppRouter />
          <Snackbar />
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);

export default App;
