/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, SyntheticEvent, useEffect } from 'react';
import { Box, Checkbox, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '../../components/Typography/Typography';
import { ArtifactWrapper } from './BUArtifact.styled';
import { ListTable } from '../../components';
import { categories } from '../../libs/Constant';
import { CommHandler } from '../../utils';
import { config } from '../../config';
import { getCurrentYear } from '../../utils/utils';
import { setSelectedStatus, setHasArtifact, setTabValue } from '../Profile/selectedStatusSlice';
export default function Artifact() {
  const [tabData, setTabData] = useState([]);
  const [data, setData] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedUser = useSelector((state: any) => state.user.employee);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const currentYear = (
    useSelector((state: any) => state.appraisalCycle.selectedYear)
      || getCurrentYear()
  )?.toString();

  const { selectedStatus: selectStatus, hasArtifact, tabValue } = useSelector((state:any) => state?.selectedStatus);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setSelectedStatus(
      typeof value === 'string' ? value.split(',') : value,
    ));
  };

  const names = [
    'Pending',
    'Approved',
    'Rejected',
  ];

  useEffect(() => {
    setTabData(data[tabValue]);
  }, [data]);

  const handleTabChange = async (event: SyntheticEvent, newValue: any) => {
    dispatch(setTabValue(newValue));
    setTabData(data[newValue]);
  };

  const handleActionClick = (action: any, item: any) => {
    const userId = item?.user_id;
    if (action.label === 'View') {
      navigate(`/user-artifact-detail/${userId}`);
    }
  };

  const handleCheckboxChange = async (showArtifacts) => {
    try {
      setLoadingTable(true);

      const params = {
        hasArtifact: showArtifacts ? 1 : 0,
        approved: selectStatus?.join(',').toLocaleLowerCase(),
        year: currentYear,
      };

      if (!showArtifacts) {
        delete params.hasArtifact;
      }
      if (!selectStatus.length) {
        delete params.approved;
      }

      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/users`,
        method: 'GET',
        params,
      });
      if (response?.data?.length) {
        setData(response?.data[0] || {});
      }
    } catch (e) {
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    handleCheckboxChange(hasArtifact);
  }, [selectStatus, currentYear]);

  const getTabComponent = () => Object.keys(categories)
    .filter((key) => {
      switch (categories[key]) {
        case categories.OTHERS: {
          return data?.[categories.OTHERS]?.length || false;
        }
        case categories.DIRECT_REPORTING: {
          return loggedUser?.isReportingManager === true || false;
        }
        case categories.BU: {
          return (
            loggedUser?.isBUHead === true || loggedUser?.isHRBP === true || false
          );
        }
        case categories.PROJECT: {
          return loggedUser?.isProjectManager === true || false;
        }
        default:
          return true;
      }
    })
    .map((key) => (
      <Tab
        key={categories[key]}
        className="live-rank-tab-container"
        label={`${categories[key]}`}
        value={categories[key]}
      />
    ));

  const col = [
    {
      header: 'Employee Name',
      accessor: 'fullName',
    },
    {
      header: 'Employee Email',
      accessor: 'email',
    },
    {
      header: 'Business Unit',
      accessor: 'department',
    },
  ];

  const report = [
    {
      icon: <VisibilityIcon />,
      label: 'View',
      onClick: () => {},
      color: 'primary-icon',
    },
  ];

  return (
    <ArtifactWrapper>
      <div className="artifact-btn-space">
        <div>
          <Typography variant="h1" gutterBottom>
            Team Artifacts
          </Typography>
        </div>
      </div>
      <div className="employee-nav">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <TabContext value={tabValue!}>
            <TabList onChange={handleTabChange}>
              {getTabComponent()}
            </TabList>
          </TabContext>
          <Box display="flex" alignItems="center" marginLeft="auto">
            <FormControl sx={{ m: 1, width: 250 }}>
              <InputLabel id="demo-multiple-checkbox-label">Filter</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectStatus}
                onChange={handleChange}
                input={<OutlinedInput label="Filter" />}
                renderValue={(selected) => selected?.join(', ')}
                MenuProps={MenuProps}
              >
                {names?.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectStatus?.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" alignItems="center" marginLeft="auto">
              <Checkbox
                checked={hasArtifact}
                onChange={() => {
                  dispatch(setHasArtifact(!hasArtifact));
                  handleCheckboxChange(!hasArtifact);
                }}
                color="primary"
              />
              <Typography
                style={{
                  fontSize: '14px', fontWeight: '500', fontFamily: 'Inter', color: 'rgb(0, 0, 0)',
                }}
                variant="body1"
              >
                Has Artifacts
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
      <ListTable
        tabValue={tabValue!}
        columns={col}
        data={tabData?.sort((a, b) => a?.fullName.localeCompare(b?.fullName)) || []}
        actions={report}
        onActionClick={(action, item) => {
          handleActionClick(action, item);
        }}
        isStatus={false}
        loading={loadingTable}
      />
    </ArtifactWrapper>
  );
}
